import { Link } from "react-router-dom";

export default function CourseItem({image, courseTitle, description, link}) {
    return (
        <Link to={link} className="group">
        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
          <img src={image} alt="Person using a pen to cross a task off a productivity paper card." className="h-full w-full object-cover object-center group-hover:opacity-75"/>
        </div>
        <h3 className="mt-4 text-lg text-white">{courseTitle}</h3>
        <p className="mt-1 text-sm font-medium text-slate-400">{description}</p>
      </Link>
    )
}