import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import algoCourseContent from "../../../data/courses/algo101/courseContent.json"
import CourseHome from '../../../components/CourseHome';
import LoadingOverlay from '../../../components/LoadingOverlay';

export default function Algo101() {

    const courseContentJson = JSON.parse(JSON.stringify(algoCourseContent));

    return (
      <div>
        <LoadingOverlay visible={false}/>

        <CourseHome title={courseContentJson.title} overview={courseContentJson.overview} content={courseContentJson.content} />

      </div>
        
    )
}
