'use client'


import Hero from './components/hero'



export default function Home() {
  

  return (
      <Hero/>
  )
}