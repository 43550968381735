import { useEffect, useState } from "react"
import { db } from "../../config/firebase"
import { collection, getDocs, query, setDoc, where, doc, addDoc, onSnapshot } from "firebase/firestore";
import { useAuth } from "../../hooks/useAuth";

export default function CoursePayment() {

    const [productList, setProductList] = useState([]);
    const { user, loading } = useAuth();

    useEffect(()=> {
       
        const getProducts = async () => {
            const courseArray = [];
            const q = query(collection(db, "products"), where("active", "==", true));
            const querySnapshot = await getDocs(q);

            for(const doc of querySnapshot.docs) {

                const courseId = doc.id;
                const courseName = doc.data().name;
               // console.log(`${doc.id} => ${doc.data()}`);
                const priceSnap = await getDocs(query(collection(db, "products", doc.id, "prices")));
                
                priceSnap.forEach((doc) => {
                    const priceId = doc.id;
                    const price = doc.data().unit_amount
                    const productObj = {
                        id: courseId,
                        name: courseName,
                        price: price,
                        priceId: priceId
                    }
                    courseArray.push(productObj);
                });

            }
            setProductList(courseArray); 
               
        }

        getProducts();

    }, []);

    const stripeTest= async (e) => {

        e.preventDefault();

        const docRef = await addDoc(collection(db, "users", user.uid, "checkout_sessions"), {
            mode: "payment",
            price: "price_1PbrlbJnDit8kRFWvuonel9b", // One-time price created in Stripe
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });

        onSnapshot(docRef, (snap)=>{

            const {error, url} = snap.data();
            if(error) {
                console.log(error.message);
            }
            if(url) {
                window.location.assign(url);
            }

        });
    }

    const jwtToken = async (e) => {
        e.preventDefault();
        const token = await user.getIdToken();
        console.log(token);
    }

    return (
        <div>
            {productList.map(product => 
                <p>{product.id}-{product.name}</p>)}
        <button onClick={stripeTest}>Subscribe</button>
        <button onClick={jwtToken}>Create Token</button>
        </div>
    )
}