// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GithubAuthProvider, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAb6RDSY3H3XuKIKfj_bDdhHzCDsnLw8Lk",
  authDomain: "leetgrind-57a35.firebaseapp.com",
  projectId: "leetgrind-57a35",
  storageBucket: "leetgrind-57a35.appspot.com",
  messagingSenderId: "498637157480",
  appId: "1:498637157480:web:b4d0ed1567bf2214a5b7dd",
  measurementId: "G-FX0H1XP2ZD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
//const analytics = getAnalytics(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();

const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
        } else {
        await setDoc(doc(db, "users", user.uid), {
            uid: user.uid,
            email: user.email,
            name: user.displayName,
            authProvider: "google",
            photoUrl: user.photoURL
            });
        } 
    }
    catch (err) {
        console.error(err);
    }
}

const singInWithGithub = async () => {
    try {
        const res = await signInWithPopup(auth, githubProvider);
        const user = res.user;
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
        } else {
        await setDoc(doc(db, "users", user.uid), {
            uid: user.uid,
            email: user.email,
            name: user.displayName,
            authProvider: "github",
            photoUrl: user.photoURL
            });
        } 
    }
    catch (err) {
        console.error(err);
    }
}

const logout = () => {
    signOut(auth);
};

export {
    auth,
    db,
    signInWithGoogle,
    singInWithGithub,
    logout
};
