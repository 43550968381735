
export default function LoadingOverlay({visible}) {

    const visibleClass = visible ? "visible": "invisible"

    return (
        <div className={`absolute bg-slate-300 bg-opacity-40 z-10 h-full w-full flex items-center justify-center ${visibleClass}`}>
             <div class="flex items-center">
                <div class="flex gap-2">
                    <div class="w-5 h-5 rounded-full animate-pulse bg-slate-800"></div>
                    <div class="w-5 h-5 rounded-full animate-pulse bg-slate-800"></div>
                    <div class="w-5 h-5 rounded-full animate-pulse bg-slate-800"></div>
                </div>
            </div>
        </div>
    )
}