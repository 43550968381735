export function getUrl(fileName, language) {
    
    var url = 'https://raw.githubusercontent.com/leetgrind/leetgrindSolutions/main';

    if(language === 'java') {
        return url + '/java/' + fileName + '.java';
    }

    else if(language === 'python') {
        return url + '/python/' + fileName + '.py';
    }

    else if(language === 'javascript') {
        return url + '/javascript/' + fileName + '.js';
    }

    else if(language === 'plaintext') {
        return url + '/docs/' + fileName + '.txt';
    }

}