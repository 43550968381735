import { useMemo, useState } from 'react';
import topicsJson from "../../../data/courses/practice/topics.json"
import TopicSelect from './topicSelect';
import arrayProblemsJson from "../../../data/courses/practice/problems/arrays.json"
import twoPointersProblemsJson from "../../../data/courses/practice/problems/twoPointers.json"
import slidingWindowProblemsJson from "../../../data/courses/practice/problems/sliding-window.json"
import stackProblemsJson from "../../../data/courses/practice/problems/stack.json"
import binarySearchProblemsJson from "../../../data/courses/practice/problems/binary-search.json"
import CodeDialog from '../../../components/CodeDialog';
import { getUrl } from './getCodeUrl';
import ProblemDialog from '../../../components/ProblemDialog';





export default function PracticeCourse() {
  const parseProblem = (problemsJson) => {
    return JSON.parse(JSON.stringify(problemsJson));
  }
  const findTopicProblems = (topicId) => {
    switch(topicId) {
      case 0 :
        return parseProblem(arrayProblemsJson)
      case 1 :
        return parseProblem(twoPointersProblemsJson)
      case 2 :
          return parseProblem(slidingWindowProblemsJson)
      case 3 :
        return parseProblem(stackProblemsJson)
      case 4 :
        return parseProblem(binarySearchProblemsJson)
      default:
        return parseProblem(arrayProblemsJson)
    }
  }

  const topics = JSON.parse(JSON.stringify(topicsJson));
  const [topic, setTopic] = useState(topics[0]);
  const [topicProblems, setTopicProblems] = useState(findTopicProblems(0));
  const [language, setLanguage] = useState('java');
  const [selectedProblem, setSelectedProblem] = useState('');
  const [code, setCode] = useState('');
  const [problemText, setProblemText] = useState('');
  const [isProblemModalOpen, setProblemModalOpen] = useState(false);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);


  const badgeCss = {
    warning: "badge-warning",
    success: "badge-success",
    error: "badge-error"
  }

  const fetchTextFromUrl = async (url, isCode) => {

     const res = await fetch(url);
     const text = await res.text();
     if(isCode) {
      setCode(text);
      setIsCodeModalOpen(true);
     }
     else {
      setProblemText(text);
      setProblemModalOpen(true);
     }
  }

  const onQuestionClick = async (problemId, isDoc) => {
    var currentQuestion = topicProblems.filter(element => element.id === problemId)
    setSelectedProblem(currentQuestion[0].name);
    const url = isDoc ? getUrl(currentQuestion[0].codeFile, 'plaintext'): getUrl(currentQuestion[0].codeFile, language);
    await fetchTextFromUrl(url, false);
  }

  const getCode = async (problemId, language) => {
    var currentQuestion = topicProblems.filter(element => element.id === problemId)
    const url = getUrl(currentQuestion[0].codeFile, language)
    setLanguage(language);
    await fetchTextFromUrl(url, true)
  }

  const closeProblemModal = () => {
    setProblemModalOpen(false);
  }

  const closeCodeModal = () => {
    setIsCodeModalOpen(false);
  }

  const changeTopic = (topicId) => {
    setTopic(topics[topicId]);
    setTopicProblems(findTopicProblems(topicId));
  }



  return (
   <div>
    <ProblemDialog isOpen={isProblemModalOpen} heading={selectedProblem} problemText={problemText} close={closeProblemModal}/>
    <CodeDialog isOpen={isCodeModalOpen} heading={selectedProblem} code={code} close={closeCodeModal} language={language}/>
    <TopicSelect topics={topics} onTopicChange={changeTopic}/>
    <table className='border-collapse table-auto w-full text-sm'>
        <thead>
        <tr>
          <th className='border-b border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'>Number</th>
          <th className='border-b border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'>Name</th>
          <th className='border-b border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'>Level</th>
          <th className='border-b border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-200 text-left'>Solution</th>
        </tr>
      </thead>
      <tbody className='bg-slate-800'>
    
    {
      topicProblems.map(problem => 
      {
        return(
          <tr key={problem.id} className='odd:bg-slate-700 even:bg-slate-800'>
          <td className='border-b border-slate-700 p-4 pl-8 text-slate-400'>{problem.id}</td>
          <td className='border-b underline border-slate-700 p-4 pl-8 text-slate-400 hover:text-slate-100 cursor-pointer' onClick={() => onQuestionClick(problem.id, true)}>{problem.name}</td>
          <td className='border-b border-slate-700 p-4 pl-8 text-slate-400'>

            <div className={`badge gap-2 ${badgeCss[problem.levelColor]}`}>
              {problem.level}
            </div>
          </td>
          <td className='border-b text-xl border-slate-700 p-4 pl-8'>
            <div className='flex space-x-4'>
              <i className="fa-brands fa-java text-slate-400 hover:cursor-pointer" onClick={()=>getCode(problem.id, "java")}/>
              <i className="fa-brands fa-python text-slate-400 hover:cursor-pointer" onClick={()=>getCode(problem.id, "python")}/>
              <i className="fa-brands fa-js text-slate-400 hover:cursor-pointer" onClick={()=>getCode(problem.id, "javascript")}/>
            </div>
          </td>
        </tr>

        )
      }
        
      )
    }
    </tbody>
    </table>

   </div>
  )
}