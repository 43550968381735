import { useAuthState } from "react-firebase-hooks/auth"
import { auth, logout, signInWithGoogle, singInWithGithub } from "../config/firebase"
import { AuthContext } from "../contexts/authContext"
const AuthProvider = ({ children }) => {

    const [user, loading, error] = useAuthState(auth);
    
    const value = {
        user,
        loading,
        error,
        handleGithubLogin: singInWithGithub,
        hangleGoogleLogin: signInWithGoogle,
        handleLogout: logout
    };

    return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
    );

};

export {AuthProvider}