import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home';
import CoursesHome from './pages/coursesHome';
import Header from './components/header';
import Login from './pages/login';
import { AuthProvider } from './providers/AuthProvider';
import PracticeCourse from './pages/courses/practice';
import { ProtectedRoute } from './components/ProtectedRoute';
import Algo101 from './pages/courses/algo101';
import CoursePayment from './pages/coursesHome/payment';
import DataStructures101 from './pages/courses/dataStructures101';
import UserHome from './pages/user/home';

function App() {
  return (
    <div className="bg-slate-800">
      <AuthProvider>
        <BrowserRouter>
          <Header/>
          <div className="pt-14">
            <Routes>
              <Route index element={<Home/>}/>
              <Route path="courses" element={<CoursesHome/>}/>
              <Route path="login" element={<Login/>}/>
              <Route path="payment" element={<CoursePayment/>}/>
              <Route path="practice" element={
                <ProtectedRoute>
                  <PracticeCourse/>
                </ProtectedRoute>
              }/>
              <Route path="user/home" element={
                <ProtectedRoute>
                  <UserHome/>
                </ProtectedRoute>
              }/>
              <Route path="courses/ds101" element={<DataStructures101/>}/>
              <Route path="courses/algo101" element={<Algo101/>}/>
            </Routes>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
