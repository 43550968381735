
import PracticeImage from "../../assets/img/courses/practice.jpg"
import Overwhelmed from "../../assets/img/courses/overwhelmed.jpg"
import Data101 from "../../assets/img/courses/data_101.jpg"
import CourseItem from "./courseItem"

const courseList= [
    {
        courseTitle: "Free Problems",
        description: "Solutions to problems commonly asked in the interviews",
        link: "/practice",
        image: PracticeImage
    },
    {
        courseTitle: "Data Structures 101",
        description: "Basics of data structures",
        link: "/courses/ds101",
        image: Data101
    },
    {
        courseTitle: "Algorithms 101",
        description: "Basics of algorithms",
        link: "/courses/algo101",
        image: Data101
    },
    {
        courseTitle: "Advanced Algorithms",
        description: "Problems based on advanced algorithms and techniques",
        link: "/coming-soon",
        image: Overwhelmed
    }
]


export default function CoursesHome() {
    return (
        <div>
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Products</h2>

                <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
       {courseList.map(
        course => 
            <CourseItem 
            key={course.courseTitle}
            image={course.image} 
            courseTitle={course.courseTitle} 
            description={course.description}
            link={course.link}
            />
        )}
    </div>
  </div>
</div>
    )
}