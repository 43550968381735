import { useAuth } from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { signInWithGoogle, singInWithGithub } from "../../config/firebase";

export default function Login() {
    const {user, loading} = useAuth();
    const navigate = useNavigate();

    useEffect(()=> {
        if(loading) {
            // add a loading screen
            return;
        }
        if(user) {
            navigate("/");
        }
    }, [user, loading, navigate]);

    return(
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-slate-400">
                &lt;/&gt; Leetgrind
            </h2>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-slate-400">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">


            <div>
              <button
                type="submit"
                onClick={singInWithGithub}
                className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <span><i class="fa-brands fa-github"></i></span>
                <span className="ml-2">Sign in with Github</span> 
              </button>
            </div>
            <div>
              <button
                type="submit"
                onClick={signInWithGoogle}
                className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
               <span><i class="fa-brands fa-google"></i></span>
               <span className="ml-2">Sign in with Google</span> 
              </button>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Start a 14 day free trial
            </a>
          </p>
        </div>
      </div>
    )
}