import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import dsCourseContent from "../../../data/courses/ds101/courseContent.json"
import CourseHome from '../../../components/CourseHome';

export default function DataStructures101() {

    const courseContentJson = JSON.parse(JSON.stringify(dsCourseContent));

    return (
        <CourseHome title={courseContentJson.title} overview={courseContentJson.overview} content={courseContentJson.content} />
    )
}
