import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function CourseHome({title, overview, content}) {
  return (
    <div className="h-screen w-full pt-28 px-4">
        <div className='mx-auto w-full max-w-4xl pb-10'>
            <h2 className="text-3xl font-bold tracking-tight text-slate-400">
              {title}
            </h2>
            <h1 className='text-xl mt-5 font-bold tracking-tight text-slate-400'>Course Overview</h1>
            <p className='text-slate-400'>{overview}</p>
            <h1 className='text-xl mt-5 font-bold tracking-tight text-slate-400'>Course Content</h1>
        </div>
        
        
      <div className="mx-auto w-full max-w-4xl divide-y divide-white/5 rounded-xl bg-white/5">
        {content.map(item => 

                <Disclosure key={item.title} as="div" className="p-6">
                <DisclosureButton className="group flex w-full items-center justify-between">
                <span className="text-sm/6 font-medium text-white group-data-[hover]:text-white/80">
                    {item.title}
                </span>
                <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
                </DisclosureButton>
                <DisclosurePanel className="mt-2 text-sm/5 text-white/50">
                <ul className='list-disc'>
                    {item.topics && item.topics.map(topic =>
                        <li key={topic.name}>{topic.name}</li>
                    )}
                </ul>
                </DisclosurePanel>
                </Disclosure>
            
        )}
      </div>
    </div>
  )
}
