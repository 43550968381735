import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth"
import { db } from "../../../config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

export default function UserHome() {

    const { user, loading } = useAuth();
    const [purchases, setPurchases] = useState([]);

    useEffect(()=> {

       
        if(user) {
            const getPurchases = async () => {
                const q = query(collection(db, "users", user.uid, "payments"));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc) => {
                    const paymentsData = doc.data();
                    console.log(paymentsData.items);
                    setPurchases([...purchases, paymentsData.items[0]]);         
                });
            }
    
            getPurchases();
        }
    }, []);

    
    return(
        <div>
            <h1>Home - {user.email}</h1>
            {purchases.map(purchase => (
                <p key={purchase.id}>{purchase.description}</p>
            ))}
        </div>
    )
}